<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-5">
                <div class="single-footer-widget">
                    <h3>{{getsettingModel.value}}</h3>

                    <div class="about-the-store">
                        <p>Vakur Turizm, turlar, seyahatler, geziler, maceralar ve çok çeşitli diğer tur acenteleri için.</p>

                        <ul class="footer-contact-info">
                            <li><i class='bx bx-phone-call'></i> <a href="tel:+03125522323">{{getsettinggModel.value}}</a></li>
                            <li><i class='bx bx-envelope'></i> <a href="mailto:info@vakurturizm.com">{{getmailModel.value}}</a></li>
                            <li><i class='bx bx-map'></i> <a href="https://www.google.com/maps/place/M%C3%9CSDAV/@40.4682026,28.6944101,7z/data=!4m10!1m2!2m1!1sm%C3%BCsdav+adres!3m6!1s0x14d34e42e68a7fef:0xeff206856f992fc8!8m2!3d39.9342068!4d32.8666201!15sCg1tw7xzZGF2IGFkcmVzIgJIAZIBCmZvdW5kYXRpb27gAQA!16s%2Fg%2F11ggpkfcfy" target="_blank">{{getadressModel.value}}</a></li>

                        </ul>
                    </div>

                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/vakurturizm?mibextid=ZbWKwL" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://x.com/vakurturizm?t=AZ6OIetYCJXn49eUfITD5w&s=08" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://instagram.com/vakurturizm?igshid=MTk0NTkyODZkYg==" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="single-footer-widget pl-4">
                    <h3>Hızlı Linkler</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/">Anasayfa</a></li>
                        <li><a routerLink="/listing-four">Turlar</a></li>
                        <li><a routerLink="/about">Hakkımızda</a></li>
                        <li><a routerLink="/faqs">SSS</a></li>
                        <li><a routerLink="/contact">İletişim</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Vakur Öneriyor </h3>

                    <ul class="customer-support">
                        <li><a routerLink="/login">Kış Tatili</a></li>
                        <li><a routerLink="/checkout">Yaz Tatili</a></li>
                        <li><a routerLink="/cart">Kış Turları</a></li>
                        <li><a routerLink="/faqs">Yaz Turları</a></li>
                        
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="single-footer-widget">
                    <h3>Kampanyalarımızdan haberdar olabilirsiniz!</h3>

                    <div class="footer-newsletter-box">
                        <p>Bizden en son haberleri ve en son güncellemeleri almak için.</p>

                        <form id="subscriptionForm" [formGroup]="form"  (ngSubmit)="subscriptionForm()" class="newsletter-form"  data-toggle="validator">
                            <div class="col-lg-12 col-md-6">
                               <div class="form-group mb-3">
                                   <label>E-Posta <span>*</span></label>
                                   <input type="email" id="email" name="email" formControlName="email"  class="form-control" placeholder="E-Posta Adresiniz">
                               </div>
                           </div>
                           <button type="submit">Abone Ol</button>
                       </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Designed and Developed by  <a href="https://vakifglobal.com/" target="_blank"><img src="assets/img/VAKIF_GLOBAL_LOGO.png" alt="" style="width: 150px;"></a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Gizlilik Politikası</a></li>
                        <li><a routerLink="/">Şartlar ve Koşullar</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>

<div class="modal right fade shoppingCartModal" id="shoppingCartModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>

            <div class="modal-body">
                <h3>My Cart (3)</h3>

                <div class="products-cart-content">
                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="/"><img src="assets/img/products/img1.jpg" alt="image"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="/">Ham Salad</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $20
                            </div>
                            <a routerLink="/" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img2.jpg" alt="image"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Fresh Cappuccino</a></h3>
                            <span>Quantity: 02</span>
                            <div class="products-price">
                                $25
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img3.jpg" alt="image"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Honey Cake</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $11
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>
                </div>

                <div class="products-cart-subtotal">
                    <span>Subtotal</span>

                    <span class="subtotal">$524.00</span>
                </div>

                <div class="products-cart-btn">
                    <a routerLink="/cart" class="default-btn">View Bag & Checkout</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>