<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>İletişim</h2> -->
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <h3>İletişim Bilgileri</h3>
                    <ul class="contact-list">
                        <li><i class='bx bx-phone-call'></i> <a href="tel:+03125522323">{{getsettinggModel.value}}</a></li>
                        <li><i class='bx bx-envelope'></i> <a href="mailto:info@vakurturizm.com">{{getmailModel.value}}</a></li>
                        <li><i class='bx bx-map'></i><a href="https://www.google.com/maps?q=m%C3%BCsdav&um=1&ie=UTF-8&sa=X&ved=2ahUKEwic_8TarML-AhUgSvEDHWr2CmAQ_AUoAnoECAEQBA">{{getadressModel.value}}
                            </a></li>

                    </ul>
                    <h3>Çalışma Saatleri:</h3>
                    <ul class="opening-hours">
                        <li><span>{{getworkinghoursModel.value}}</span></li>
                       
                    </ul>
                    <h3>Bizi takip edin:</h3>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/vakurturizm?mibextid=ZbWKwL" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://x.com/vakurturizm?t=AZ6OIetYCJXn49eUfITD5w&s=08" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://instagram.com/vakurturizm?igshid=MTk0NTkyODZkYg==" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>Sorularınız, önerileriniz veya talepleriniz için bizimle iletişime geçebilirsiniz...</h3>
                    <form id="contactForm" [formGroup]="form" (ngSubmit)="submitForm()">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Ad<span>*</span></label>
                                    <input type="text"  name="firstName" formControlName="firstName" id="name" class="form-control" placeholder="Ad">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Soyad <span>*</span></label>
                                    <input type="text"  name="lastName" formControlName="lastName" id="name" class="form-control" placeholder="Soyad">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>E-Posta <span>*</span></label>
                                    <input type="email" id="email" name="email" formControlName="email"  class="form-control" placeholder="E-Posta Adresiniz">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Telefon <span>*</span></label>
                                    <input type="number" name="phone" id="phone" formControlName="phone" class="form-control" placeholder="Telefon Numaranız">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Konu<span>*</span></label>
                                    <input type="text"  name="subject" formControlName="subject" id="name" class="form-control" placeholder="Mesajınızı yazınız.">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Mesaj <span>*</span></label>
                                    <textarea name="message" id="message" formControlName="message" cols="30" rows="5" class="form-control" placeholder="Mesajınızı yazınız..."></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit"  class="default-btn">Gönder</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3108166.8734399383!2d28.69441011772157!3d40.46820255762592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34e42e68a7fef%3A0xeff206856f992fc8!2zTcOcU0RBVg!5e0!3m2!1str!2str!4v1682337164314!5m2!1str!2str"></iframe>
</div>