import { Component, OnInit } from '@angular/core';
import { ReservationPerson } from '../models/reservation-person';
import Swal from 'sweetalert2';
import { Reservation } from '../models/reservation';
import { FormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FrontApiService } from '../services/front-api.service';
import { TourService } from '../services/tour.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  gettourModel!:any;

  tourId: any;
  tourDetail: any;
  exitPointId: number;
  reservationForm: FormGroup
  showDiffInvoice: boolean;
  tourPrice: number;
  datePickerConfig = {
    format: 'YYYY-MM-DD'
  };
  personCount: any;
  childCount: any;
  countedPrice: any;
  showChildPrice: void;
  childPrices: any;
  ChildPrice:any;
  
  path = environment.serverUrl
  price: number;
  constructor(
    private router: ActivatedRoute,
    private tourService:TourService,
    private fb: FormBuilder,
    private front: FrontApiService
  ) {
    const reservationData = JSON.parse(localStorage.getItem("reservationData"));
    this.personCount = reservationData.personCount;
    this.childCount = reservationData.childCount
    this.countedPrice = reservationData.countedPrice;
    this.childPrices = reservationData.childPrices
    this.tourId = router.snapshot.params.id;
  }

  ngOnInit(): void {
    this.loadTourDetail()
    this.createForm()

  }

  loadTourDetail() {
    this.tourService.getid(this.tourId).subscribe(resp => {
      this.tourDetail = resp;
      this.tourPrice = this.tourDetail.ac.filter(item => item.languageCode == "tr")[0].price;
    })
  }
  
  createForm() {
    this.reservationForm = this.fb.group({
      activeTourId: this.tourId,
      ContactFirstName: [''],
      ContactLastName: [''],
      ContactEmail: ['', [Validators.required, Validators.email]],
      ContactPhone: ['', Validators.required],
      ContactAddress: ['', Validators.required],
      ContactPostalCode: ['', Validators.required],
      ContactDoorNumber: ['', Validators.required],
      ContactCity: ['', Validators.required],
      ContactCountry: ['', Validators.required],
      InvoiceTitle: [''],
      InvoicePostalCode: [''],
      InvoiceDoorNumber: [''],
      InvoiceAddress: [''],
      InvoiceCity: [''],
      InvoiceCountry: [''],
      TotalAmount: [0],
      ReservationPersons:this.fb.array([this.personForm()])
    })
    
    this.addPersonForm()
}
  // Kişi form

  get ReservationPersons(): UntypedFormArray {
    return this.reservationForm.get("ReservationPersons") as UntypedFormArray
}
  addPersonForm(){
    for(let i=0; i<this.personCount-1;i++){
        this.ReservationPersons.push(this.personForm())
    }
  }

  personForm():UntypedFormGroup{
    console.log("DETAYYYYY",this.tourDetail)
    const ep = this.tourDetail?.tourExitPointId;
    return this.fb.group({
            FirstName:['',Validators.required],
            LastName:['',Validators.required],
            Phone:['',Validators.required],
            BirthDay:['',Validators.required],
            Gender:['',[Validators.required]],
            tourDepartureId:['23',[Validators.required]],
            // tourDepartureId:[23],
            tourPriceId:[7]
    })
  }

  createBuildObject(): Reservation {

    const reservation: Reservation = {
      activeTourId: this.tourId,
      ContactFirstName: this.reservationForm.get('ContactFirstName').value,
      ContactLastName: this.reservationForm.get('ContactLastName').value,
      ContactEmail: this.reservationForm.get('ContactEmail').value,
      ContactPhone: this.reservationForm.get('ContactPhone').value,
      ContactAddress: this.reservationForm.get('ContactAddress').value,
      ContactPostalCode: this.reservationForm.get('ContactPostalCode').value,
      ContactDoorNumber: this.reservationForm.get('ContactDoorNumber').value,
      ContactCity: this.reservationForm.get('ContactCity').value,
      ContactCountry: this.reservationForm.get('ContactCountry').value,
      InvoiceTitle: this.reservationForm.get('InvoiceTitle').value,
      InvoiceAddress: this.reservationForm.get('InvoiceAddress').value,
      InvoicePostalCode: this.reservationForm.get('InvoicePostalCode').value,
      InvoiceDoorNumber: this.reservationForm.get('InvoiceDoorNumber').value,
      InvoiceCity: this.reservationForm.get('InvoiceCity').value,
      InvoiceCountry: this.reservationForm.get('InvoiceCountry').value,
      TotalAmount: this.countedPrice,
      ReservationPersons:(this.ReservationPersons.value as ReservationPerson[])
    }
    return reservation;
  }
  addReservation(){
    console.log(this.reservationForm)
    const reservation = this.createBuildObject();
    console.log(reservation);
    // reservation.TotalAmount = reservation.ReservationPersons.length*100
    if(this.reservationForm.valid){
        
        this.tourService.addReservation(reservation).subscribe((resp:any)=>{
            console.log(resp)
            if (resp.statusCode == 200) {
              Swal.fire({
                title: 'Teşekkür ederiz',
                icon: 'success',
                html: '<p>Başvurunuz alınmıştır. Belirtmiş olduğunuz Mail adresinize bilgileriniz gönderilmiştir.</p>' +
                  '<p>Faturanız ve bilgilendirme dosyayı için </p> ' +
                  '<a target="_blank" href="https://vakurapi.musdav.org.tr/reservation/ticket_' + resp.data.id + '.pdf" style="color:#088dd3;"> tıklayın </a> ',
                confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
    
              }).then(function (result) {
                if (result.value) {
                  window.location.href = '/listing-two';
                }
              })
            }
          }, (err) => {
            alert("HATA")
          })
        }
    else{
        this.reservationForm.markAllAsTouched()
        // alert("Lütfen tüm alanları doldurunuz")
    }
  }

}