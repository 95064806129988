<section class="process-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">İşlem</span> -->

            <h2>Hayallerine bir adım daha yaklaş...</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-process-box">
                    <div class="icon">
                        <i class="flaticon-tap"></i>
                    </div>
                    <div class="content">
                        <h3>Bir kategori seçin</h3>
                        <!-- <p>Yurtdışı Turlar <br>
                            Yurtiçi Turlar<br>
                            Otel Rezervasyonu</p> -->
                        <!-- <div class="number">1</div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-process-box">
                    <div class="icon">
                        <i class="flaticon-find"></i>
                    </div>
                    <div class="content">
                        <h3>Ne İstediğinizi Bulun</h3>
                        <!-- <p>Uçaklı Turlar <br>
                            Otobüs ile Tur<br>
                            Bireysel Rezervasyon</p> -->
                        <!-- <div class="number">2</div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-process-box">
                    <div class="icon">
                        <i class="flaticon-explore"></i>
                    </div>
                    <div class="content">
                        <h3>Ve keşfedin...</h3>
                        <p></p>
                        <!-- <div class="number">3</div> -->
                    </div>
                </div>
            </div>
<!-- 
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div> -->
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>