<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>About Us</h2> -->
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title"></span>
                    <h2>{{getaboutModel.name}}</h2>
                    <!-- <h6>{{getaboutModel.value}}</h6> -->
                    <p style="text-align: justify;">{{getaboutModel.value}}</p>

                    <!-- <div class="features-text">
                        <h5><i class='bx bx-planet'></i>Start managing your business profile on Louise today</h5>
                        <p>More than just a local listing, your free Business Profile lets you manage how your business appears on <strong>Louise</strong> Search.</p>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/img1.png" class="shadow" alt="image">
                    <!-- <img src="assets/img/about/img2.jpg" class="shadow" alt="image"> -->
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>{{getmissionModel.name}}</h3>
                        <p>{{getmissionModel.value}}</p>
                        
                        <!-- <ul class="features-list">
                            <li><i class='bx bx-check'></i>vakurturizm.com’da sayfanın üst bölümündeki "Kayıt Ol" bağlantısına tıklayıp üyelik bilgilerinizi girerek kaydınızı yapın.</li>
                            <li><i class='bx bx-check'></i>Tebrikler! Artık siz de vakurturizm.com'un bir üyesisiniz. Anasayfadan "Tur Ara" bölümünden istediğiniz özelliklerdeki, sayısız tur seçeneğini incelemeye başlayabilirsiniz.</li>
                            <li><i class='bx bx-check'></i>Seçtiğiniz otel ya da turla ilgili ayrıntılı bilgileri "Detayları İncele" butonunu tıklayarak görebilirsiniz.</li>
                            <li><i class='bx bx-check'></i>Hakkında daha detaylı bilgi almak istediğiniz tatil seçenekleriyle ilgili soru sormak için, iletişim kısmındaki formu dolduruğ ilgili rezervasyonun "Soru Sor" butonuna tıklayın. Konuyla ilgili Tatil Danışmanlarımız en kısa sürede sizinle iletişime geçip sizi bilgilendirecektir.</li>
                            <li><i class='bx bx-check'></i>Tüm bilgileri aldıktan sonra tatil ya da tur rezervasyonunuzu yaptırmak için ilgili tatil seçeneğinin alt kısmında bulunan "Rezervasyon Yap" kutucuğunu kullanarak dilediğiniz tarih aralığı ve kişi sayısı için satın alma işlemini tamamlayabilirsiniz.</li>
                            
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>{{getvisionModel.name}}</h3>
                        <p>{{getvisionModel.value}}</p>
                        
                        <!-- <ul class="features-list">
                            <li><i class='bx bx-check'></i> Learning & Support</li>
                            <li><i class='bx bx-check'></i> Claim your listing</li>
                            <li><i class='bx bx-check'></i> Find yourself</li>
                            <li><i class='bx bx-check'></i> Complete your listing profile</li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>{{getwhyweexistModel.value}}</h3>
                        <p style="text-align: justify;">{{getwhyweexistModel.name}}</p>
                        
                        <!-- <ul class="features-list">
                            <li><i class='bx bx-check'></i>3 binden fazla yurt içi oteli</li>
                            <li><i class='bx bx-check'></i>Yurt içi ve yurt dışı turları</li>
                            <li><i class='bx bx-check'></i>Yurt içi ve yurt dışı uçak biletleri</li>
                            <li><i class='bx bx-check'></i>100 binden fazla yurt dışı oteli</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-video></app-video> -->

<!-- <section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Team</span>
            <h2>Meet Our Team</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img1.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img2.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img3.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img4.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>John Capabel</h3>
                        <span>Programer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <app-funfacts></app-funfacts> -->

<!-- <app-partner></app-partner> -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-instagram></app-instagram> -->