<header class="header-area">
    <div class="top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="header-contact-info">
                        <li>{{getsettingModel.value}}</li>
                        <li> <i class="bx bx-phone-call"> {{getphoneModel.value}}</i><a href="tel:+03125522323"></a></li>
                        <!-- <li>
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img src="assets/img/us-flag.jpg" alt="image">
                                    <span>Eng <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu" [class.active]="classApplied">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Ger</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Fre</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Spa</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Rus</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Ita</span>
                                    </a>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="top-header-discount-info">
                        <p><strong>{{getslogModel.value}}</strong></p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">
                        <!-- <li><a routerLink="/login"><i class='bx bxs-user'></i> My Account</a></li>
                        <li><a href="/admin-dashboard/add-listing"><i class='bx bx-plus-circle'></i> Add Listing</a></li> -->
                        <!-- <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i>Üye Ol</a></li>
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i>Giriş Yap</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav" style="background-image: url(assets/img/Vakur_BG_Beyaz.png);margin-left: -1%;margin-top: -4px;">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/img/logo.png" alt="logo" style="width: 55%;">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ANASAYFA</a></li>
                            <li class="nav-item"><a routerLink="/listing-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">TURLAR</a></li>
                            <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">HAKKIMIZDA</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İLETİŞİM</a></li>
                            <!-- <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kampanyalar</a></li> -->



    
                        </ul>

                        <!-- <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <div class="search-btn-box">
                                        <i class="search-btn bx bx-search-alt"></i>
                                    </div>
                                </div>
    
                                <div class="option-item">
                                    <div class="cart-btn">
                                        <a href="#" data-toggle="modal" data-target="#shoppingCartModal"><i class='bx bx-shopping-bag'></i><span>0</span></a>
                                    </div>
                                </div>
    
                                <div class="option-item">
                                    <a href="/admin-dashboard/add-listing" class="default-btn"><i class="bx bx-plus"></i> Add Listing</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                          
              
                </nav>
            </div>
        </div>
    </div>
</header>