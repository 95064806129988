<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <!-- <div class="page-title-content">
            <h2>Listing Details</h2>
        </div> -->
    </div>
</div>

<section class="listing-details-area pt-100 pb-70">
    <div class="container">
        <div class="listing-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="listing-title">
                        <h2>{{gettourModel.tourRecord.title}}</h2>
                        <p>{{gettourModel.shortDescription}}</p>
                    </div>

                    <div class="listing-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Kontenjan</span>
                                <a routerLink="/">{{gettourModel?.quota-gettourModel.reservationTotalPerson}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Rezervasyon olan kİşİ sayısı</span>
                                <a routerLink="/">{{gettourModel.reservationTotalPerson}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Tur Tarihi</span>
                                <a routerLink="/"> {{gettourModel?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{gettourModel?.endDate | date:'dd/MM/yyyy' :'TR'}} </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- {{gettourModel?.dayCount-1}} Gece {{gettourModel?.dayCount}} Gün | -->
                <!-- <div class="col-lg-4">
                    <div class="listing-price">
                        <div class="listing-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div>

                        <div class="price">$80 - $250</div>
                        <a routerLink="/listing-details" class="default-btn">Claim Now!</a>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12" >
                <div class="listing-details-image-slides owl-carousel owl-theme">
                    <div class="listing-details-image text-center" *ngFor="let item of gettourModel?.tourMedias;">
                        <img [src]="path+'tours/'+item.fileName">
                    </div>

                </div>
                

                <div class="listing-details-desc">
                    <h3>Başvuru ve Kayıt İşlemleri
                    </h3>
                    <p>{{gettourModel.description}}</p>

                    <ul class="description-features-list">
                        <li>Umreye gitmek isteyenler ilan edilen umre turuna ait banka kodu ile Vakur Turizm’in anlaşmalı
                            bankalara açtıran umre kurumsal tahsilat hesabına ücretini peşin olarak yatıracaklardır.</li>
                        <li>Ücretler Vakıf Katılım Bankası Şubesi’nden Yatırılabilecektir.</li>
                        <li>Ücretini yatıran umre yolcuları Vakur Turizme müracaat ederek veya elektronik ortam üzerinden
                            kayıtlarını yapabileceklerdir.</li>
                        <li>Umre yolcusu 2023-2024 umre kayıt form dilekçesini 2 nüsha halinde doldurarak ilgili Vakur
                            Turizm’e teslim edecektir.</li>
                        <li>Vakur Turizm’e geçerlilik süresi en az bir (1) yıl olan pasaport teslim edilecektir.</li>
                        <li>Umre yolcusu banka dekontu ile birlikte il sağlık müdürlüklerince belirlenen sağlık merkezlerinden
                            birine giderek, Konjuge Meningokoksit Menenjit aşısını yaptıracak ve aşı kartını umre suresi
                            boyunca yanında bulunduracaktır.</li>

                    </ul>
                    <h3>Not</h3>
                    <ul class="description-features-list">
                        <li>Kayıtlar için acele ediniz, kontenjanlar son başvuru tarihinden önce dolabilmektedir.</li>
                        <li>Uçakların gidiş geliş tariklerinde yoğunluk, slot durumu veya olağan dışı durumlar ve diğer etkenler
                            sebebiyle değişiklik olabilmektedir. Bu gibi istisnai durumlarda Kafilelerin uçuşları broşür dışına
                            çıkılarak en fazla 7 gün önce veya sonrasına planlama yapılabilecektir.</li>
                        <li>Herhangi bir sebeple umre vizesi alındıktan sonra vazgeçen ziyaretçilerden umre vize ücreti
                            kesintisi yapılacaktır.</li>
                        <li>Aynı ilden kayıt yaptırıp farklı havalimanı seçenler iç hat bağlantısı imkânı olmaması durumunda
                            kayıt yapılan ilin seyahat şartlarına uygun hareket edecektir.</li>
                        <li>Diyanet işleri Başkanlığı belirtilen programların iptaline, gidiş-dönüş tarihlerinde değişiklik
                            yapmaya ve gerekli hallerde her türlü tedbiri almaya yetkilidir.</li>
                        

                    </ul>
                    <section class="faq-area ptb-100">
                        <div class="container">
                            <div class="tab faq-accordion-tab">
                                <ul class="tabs d-flex flex-wrap justify-content-center">
                                    <li><a href="#"><i class='bx bx-flag'></i> <span>Otel Bilgileri</span></a></li>
                                    <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span> Hizmetlerimiz & Hediyelerimiz</span></a></li>
                                    <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Genel Bilgiler</span></a></li> 
                                </ul>
                                <div class="tab-content">
                                    <div class="tabs-item">
                                        <div class="faq-accordion accordion" id="faqAccordion" >
                                            <div class="accordion-item" *ngFor="let item of gettourModel?.tourDays; let i = index;">
                                                <div class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapse{{i}}" aria-expanded="true" attr.aria-controls="collapse{{i}}">
                                                        {{item.title}} <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapse{{i}}" class="accordion-collapse collapse" data-bs-parent="#faqAccordion1">
                                                    <div class="accordion-body">
                                                        <p innerHTML="{{item.description}}"> </p>
                                                    </div>
                                                </div>
                                            </div>
                                
                             
                                        </div>
                                    </div>
                                    <div class="tabs-item">
                                        <div class="faq-accordion accordion" id="faqAccordion2">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                                        Ücrete Dahil Hizmetlerimiz <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                                    <div class="accordion-body">
                                                        <ul *ngFor="let item of gettourModel.tourSpecifications"> 
                                                            <p *ngIf="item.inPrice == true" innerHTML="{{item.specification}}"> </p>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                        Hediyelerimiz <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                                    <div class="accordion-body">
                                                        <ul *ngFor="let item of gettourModel.tourSpecifications">
                                                            <p *ngIf="item.inPrice == false" innerHTML="{{item.specification}}"> </p>
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                     
                                        </div>
                                    </div>
                                     <div class="tabs-item">
                                        <div class="faq-accordion accordion" id="faqAccordion3">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                                        Genel Bilgilendirme <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseEleven" class="accordion-collapse collapse " data-bs-parent="#faqAccordion3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <p>- 21.01.2023 Tarihinde Uçuş saatinden 3 saat önce tüm yolcularımızın belirtilen Havalimanında hazır bulunmaları
                                                                gerekmektedir.
                                                                <br>
                                                                - Check-in ve Bagaj işlemleri için geç kalınmaması önem arz etmektedir.
                                                                <br>
                                                                - Pasaportunuzu bavulunuza veya bagaja vereceğiniz eşyalarınız içene koymayınız. Kontrol noktalarında belgelerin
                                                                yanınızda olması zorunludur.
                                                                <br>
                                                                - Uçak içine ambalajsız, reçetesiz ya da doktor raporsuz ilaçlar, sıvı maddeleri, yanıcı (parfüm, kolonya vb.), kesici,
                                                                delici, ezici eşyalar alınmamaktadır.
                                                                <br>
                                                                - Gruptan mümkün olduğunca ayrılmayınız. Gruptan ayrılmanızı gerektirecek bir durum olması halinde din görevlisine
                                                                ve rehbere mutlaka bilgi veriniz.
                                                                <br>
                                                                - Sürekli kullandığınız ilaçlar var ise 15 gün boyunca yetecek şekilde temin ediniz, gidilen ülkede aynı ilaçları veya
                                                                muadilini bulmakta güçlük çekebilirsiniz, bu ilaçlara ait raporlarınız var ise mutlaka yanınıza alınız. </p>
                    
                                                        </ul>                                </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                        Uçak Bagaj Hakkı <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                        <p>- Bagaj 30 Kg + 8 Kg (uçak içi) olacak şekildedir. (Belirtilen kilodan fazla olması yolcunun sorumluluğundadır.)
                                                            <br>
                                                            - Bagaj teslimatında size teslim edilen bagaj etiketini kaybetmemeniz önemle rica olunur. Olası bagaj kaybında bu
                                                            etiket bagajınızı bulabilmeniz hususunda size avantaj sağlayacaktır. </p>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelvee" aria-expanded="false" aria-controls="collapseTwelvee">
                                                        Suudi Arabistan Resmi Para Birimi <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseTwelvee" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                                    <div class="accordion-body">
                                                        <ul>
                                                        <p>- Suudi Arabistan resmi para birimi Riyal’dir. SAR kısaltmasıdır.
                                                            <br>
                                                            - Genel olarak dolar ve riyal kullanımı yaygındır.
                                                            <br>
                                                            - Otellerimiz şehir merkezinde yer almaktadır. Rehberimiz şehir merkezinde bulunan döviz bürolarında size yardımcı
                                                            olacaktır.</p>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelveee" aria-expanded="false" aria-controls="collapseTwelveee">
                                                        GSM Hattı <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseTwelveee" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                                    <div class="accordion-body">
                                                        <ul>
                                                        <p>- GSM hatlarınızı kullanmak için uçuştan 24 Saat önce servis sağlayıcınızdan Suudi Arabistan’da kullanım şartları
                                                            hakkında bilgi alınız. (Mümkün ise yurt dışı paket tarifesi tanımlatmanızı tavsiye ederiz.)
                                                            <br>
                                                            - İnternet altyapısı zayıf olduğundan havalimanına indiğinizde veya şehir merkezinde geçici hat ve internet paketi
                                                            pasaportunuzla alabilirsiniz.</p>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelveea" aria-expanded="false" aria-controls="collapseTwelveea">
                                                        Otel Bilgilendirme <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseTwelveea" class="accordion-collapse collapse" data-bs-parent="#faqAccordion6">
                                                    <div class="accordion-body">
                                                        <ul>
                                                        <p>- Konaklama yapacağımız otel odalarında havlu, terlik, saç kurutma makinesi vb. mevcuttur. Olmaması durumunda
                                                            resepsiyonu arayıp talepte bulunabilir veya rehberimizden yardım isteyebilirsiniz.
                                                            <br>
                                                            - Otele vardığınızda lobiden misafirhanenin adresini belirtir kartvizit alınız ve program boyunca yanınızdan
                                                            ayırmayınız.</p>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- <h3>Aktiviteler</h3> -->

                    <!-- <div class="amenities-list">
                        <ul>
                            <li>
                                
                                <span>
                                    <i class='bx bx-check'></i>
                                    Elevator in Building
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Instant Book
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Friendly Workspace
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Wireless Internet
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Free Parking on Premises
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Free Parking on Street
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Air Conditioned
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Live Music
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Accepting Credit Cards
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Pool
                                </span>
                            </li>
                        </ul>
                    </div> -->

                    <!-- <h3>Konum</h3> -->

                    <!-- <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3108166.8734399383!2d28.69441011772157!3d40.46820255762592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34e42e68a7fef%3A0xeff206856f992fc8!2zTcOcU0RBVg!5e0!3m2!1str!2str!4v1682337164314!5m2!1str!2str"></iframe>
                    </div> -->

                    <!-- <h3>Önemli Not</h3>

                    <div class="listing-author" >
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">

                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>Murat Kuyubaşı</h4>
                                    </div>
                                </div>
                            </div>
                            <p>NOT: GİDİLECEK ÜLKENİN PANDEMİ KURALLARI GEÇERLİ OLACAĞINDAN PANDEMİ ŞARTLARININ
                                SAĞLANMASI YOLCUNUN SORUMLULUĞUNDADIR.</p>
                        </div>
                    </div> -->

                     <div class="listing-review-comments">
                        <h3>{{getcommentlistModel.length}} Yorum</h3>

                        <div class="user-review" *ngFor="let item of getcommentlistModel">
                             <div class="review-rating">
                                <div class="review-stars"  >
                                     <i class='bx bxs-star'>{{item.point}}</i>
                                </div>
                            </div>


                            <strong class="d-block sub-comment">{{item.fullName}}</strong>
                             <span>{{item.creationDate | date:'dd/MM/yyyy' :'TR'}}</span>

                            <p>{{item.comment}}</p>
                        </div>
                    </div>
                    
                </div>

                <div class="comments-area">

                    <div class="comment-respond">
                        <h3 class="comment-reply-title">Yorum Bırak</h3>

                        <form id="contactForm" [formGroup]="form" (ngSubmit)="submitForm()">
                            <p class="comment-notes">
                                <span id="email-notes">E-posta hesabınız yayımlanmayacak.</span>
                                <span class="required">Zorunlu alanlar *</span>
                                ile işaretlenmiştir
                            </p>
                            <p class="comment-form-author">
                                <label>Ad<span>*</span></label>
                                    <input type="text"  name="fullName" formControlName="fullName" id="name" class="form-control" placeholder="Ad">
                            </p>
                            <!-- <p class="comment-form-author">
                                <label>Soyad <span>*</span></label>
                                <input type="text"  name="lastName" formControlName="lastName" id="name" class="form-control" placeholder="Soyad">
                            </p> -->
                            <p class="comment-form-author">
                                <label>E-Posta <span>*</span></label>
                                    <input type="email" id="email" name="email" formControlName="email"  class="form-control" placeholder="E-Posta Adresiniz">
                            </p>
                            
                            <!-- <p class="comment-form-author">
                                <label>Konu<span>*</span></label>
                                <input type="text"  name="subject" formControlName="subject" id="name" class="form-control" placeholder="Mesajınızı yazınız.">
                            </p> -->
                            <p class="comment-form-comment">
                                <label>Yorum <span>*</span></label>
                                <textarea name="comment" id="comment" formControlName="comment" cols="30" rows="5" class="form-control" placeholder="Mesajınızı yazınız..."></textarea>
                            </p>
                            <!-- <p class="comment-form-cookies-consent">
                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                            </p> -->
                            <p class="form-submit">
                                <input type="submit" name="submit" id="submit" class="submit" value="Yorum Gönder">
                            </p>
                        </form>
                    </div>
                </div>
                
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="listing-sidebar-widget">
                    <div class="listing-opening-hours">
                        <h3>Fiyat Teklifi</h3>

                         <ul>
                            <li *ngFor="let item of gettourModel?.tourPrices"><span>{{item.title}}:</span> {{item.price}} SAR</li>
                        </ul>
                    </div>
<!-- 
                     <div class="listing-contact-info">
                        <h3>  Konum Bilgisi</h3>

                        <ul>
                            <li><span>Hava Limanı:</span> <a href="#">Havalimanı: 105 km</a></li>
                            <li><span>Gezilip Görülecek Yerler:</span><br>Kalebaşı Camii: 7199 m <br> Üyük Oba Yayla Camii: 7413 m<br>
                                Düz Mahalle Camii: 9014 m<br>
                                 Canikdere Şelalesi: 23 km</li>
                            
                        </ul>
                    </div> -->

                    <!-- <div class="listing-book-table">
                        <h3>Rezervasyon</h3>

                        <form>
          
                            <div class="cart-totals">
                                <h3>Tur Ücreti  {{countedPrice}} SAR</h3>

                                <ul >
                                    <sup *ngIf="adultCount < 2" class="price"> <p style="color: #20aef9;">  Tek kişilik oda farkı + {{onePersonPrice}}₺</p></sup>

                                    <div class="input-counter"  style="float: left;left: -13px;"> 
                                        <span (click)="calculateTotalPrice(adultCount-1)" class="minus-btn"><i class='bx bx-minus'></i></span>
                                        <input (change)="calculateTotalPrice($event.target.value)" [(ngModel)]="adultCount" id="guests" type="text" min="1" value="2"  style="height: 54px;">
                                        <span (click)="calculateTotalPrice(adultCount+1)"  class="plus-btn"><i class='bx bx-plus'></i></span>
                                    </div>
                                    <li style="height: 55px;"><span style="float: unset;margin-left: -3%;"> Yetişkin  </span><img src="assets/img/yetis╠ğkin.png" alt="" style="margin-left: -9%;margin-top: -2px;width: 30%;"> </li>
                                    
                                </ul>
                                <ul >
                                  
                                    <div class="input-counter"  style="float: left;left: -13px;" > 
                                        <span (click)="calculateChildPrice(childCount-1)" class="minus-btn"><i class='bx bx-minus'></i></span>
                                        <input (change)="calculateChildPrice($event.target.value)" [(ngModel)]="childCount" id="guestsC" type="text" min="0" max="3" value="0" style="height: 54px;">
                                        <span (click)="calculateChildPrice(childCount+1)" class="plus-btn"><i class='bx bx-plus'></i></span>
                                    </div>
                                    <li><span style="float: unset;"> Çocuk  </span><img src="assets/img/cocuk.png" alt="" style="width: 19%;"> </li>
                                    
                                </ul>
                                <div *ngIf="showChildPrice">
                                    <div *ngIf="childCount>0">
                                        1. Çocuk <span *ngIf="this.child1>0">{{this.child1}} ₺</span>
                                        <select class="form-control"  name="child1" [(ngModel)]="child1" (change)="setChild($event)" >
                                            <option *ngFor=" let item of gettourModel?.tourPrices | featuredFilter : true"  value="{{item.price}}">{{item.title}}</option>
                                        </select>
                                    </div>
                                </div>
                                <h3></h3>
                         
                <button type="submit" (click)="setReservation()" class="default-btn"  *ngIf="gettourModel?.quota-gettourModel.reservationTotalPerson!=0">Rezervasyon Yap</button>
                <button type="submit" class="default-btn"  *ngIf="gettourModel?.quota-gettourModel.reservationTotalPerson ==0">Rezervasyon Kapalı</button>

                            </div>
                        </form>
                    </div> -->

                    <!-- <div class="listing-instagram">
                        <h3>Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </div> -->

                    <!-- <div class="listing-contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>