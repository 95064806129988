import { Component, OnInit } from '@angular/core';
import { TourResource } from '../models/tour-resource';
import { Period } from '../models/period';
import { Category } from '../models/category';
import { environment } from 'src/environments/environment';
import { CategoryService } from '../services/category.service';
import { PeriodService } from '../services/period.service';
import { TourService } from '../services/tour.service';
import { Router } from '@angular/router';
import { FrontApiService } from '../services/front-api.service';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { TourModel } from '../models/tourModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {


  tours:any=[]
  getlistModel!: Category[];
  getperiodModel!:Period[];
  gettourModel!:any;
getsearchtour:any []=[];
  tourResource:TourResource;
  tourModel:TourModel [];
  getlangtourModel!:any;


  path = environment.serverUrl

  constructor(
    private categoryService: CategoryService, 
    private periodService: PeriodService, 
    private tourService:TourService,
    private apiService:FrontApiService,
    private route:Router
    ) {
    this.tourResource = new TourResource();
    this.tourResource.pageSize = 20;
    this.tourResource.skip = 0;
    this.tourResource.orderBy = 'createdDate desc'
  }



  ngOnInit(): void {
    this.loadTours()
    this.getlist();
    this.getperiodlist();
    this.gettourlist();
    this.getlanglist();
    
  }


  searchTour(){
    console.log("Giden veriler",this.tourResource)
    this.tourService.GetsearchTour(this.tourResource.TourName,this.tourResource.PeriodName, this.tourResource.CategoryName,this.tourResource.SearchTerm, this.tourResource.RegionName).subscribe((resp: any) =>  {
      this.getsearchtour = resp;
      console.log("A",this.getsearchtour)
      localStorage.setItem('TourSearch', JSON.stringify(this.getsearchtour));
    })
    this.route.navigateByUrl("/listing-four")
  }

  loadTours(){
    this.apiService.getAllToursByLang("tr").subscribe((resp:any)=>{
      resp.forEach(element => {
        let item = element.tourMainRecords.filter(item=>item.languageCode=="tr")
        if(item.length>0){
          this.tours.push(element)
        }
        // console.log("Turlar var :)",this.tours)
      });

    })
  }

  getlist() {
    this.categoryService.getList().subscribe(data=> {
      this.getlistModel = data;
      // console.log(this.getlistModel)
    })
  }

  getperiodlist(){
    this.periodService.getList().subscribe(data=> {
      this.getperiodModel = data;
    })
  }
  gettourlist(){
    this.tourService.getList().subscribe(data=> {
      this.gettourModel = data;
      // console.log("gettour",this.gettourModel)
    })
  }

  getlanglist(){
    this.tourService.getTourList().subscribe(data=> {
      this.getlangtourModel = data;
      // console.log("okey",this.getlangtourModel)
    })
  }

  getTourCount(id:number){
    return this.gettourModel.filter(x => x.regionRecordId == id).length
  }
  selectedPopularTour(searchTerm:string){
    this.tourResource.RegionName = searchTerm;
    this.searchTour()
  }
  selectedCategoryTour(searchTerm:string){
    this.tourResource.CategoryName = searchTerm;
    this.searchTour()
  }

  swalk() {
    Swal.fire({
      title: 'Yakında rezervasyona açılacaktır!',
      confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
     }  )
    ;
}

  

  
  
}