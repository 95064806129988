<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            
        </div>
    </div>
    
</div>


<section class="listing-area ptb-100" style="padding-top: 5px;">
    
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: -3%;">
            <div class="main-search-wrap">
                <div class="row">
                    <div class="col-lg-2">
                    <p>Mevcut {{gettourModel.length}} Tur</p>
                </div>
                <div class="col-lg-10 "  >
                <hr>
                </div>
                </div>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label><img src="assets/img/Vakur_icon_YER.png" alt=""
                                        style="margin-left: -45%;margin-top: -2px;"></label>
                                <input name="name" type="text" placeholder="Gidilecek yer"
                                    [(ngModel)]="tourResource.SearchTerm">
                            </div>
                        </div>
        
                         <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><img src="assets/img/Vakur_icon_KATEGORI.png" alt="" style="margin-left: -45%;margin-top: -2px;"></label>
                                <select name="category" [(ngModel)]="tourResource.CategoryName" *ngIf="getlistModel"
                                    class="form-select"
                                    style="background-color: #ffffff;width: 100%;border-radius: 0;border: none;border-right: 1px solid #eeeeee;display: block;height: 45px;line-height: 45px;color: #7d7d7d;padding-left: 29px;padding-right: 20px;padding-top: 0;padding-bottom: 0;font-size: 14.5px;">
                                    <option value="">Kategori Seçiniz</option>
                                    <option *ngFor="let item of getlistModel" [value]="item.title"> {{item.title}}
                                    </option>
                                </select>
                            </div>
                        </div> 
        
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><img src="assets/img/Vakur_icon_DONEM_.png" alt=""
                                        style="margin-left: -45%;margin-top: -2px;"></label>
                                <select name="period" *ngIf="getperiodModel" [(ngModel)]="tourResource.PeriodName"
                                    class="form-select"
                                    style="background-color: #ffffff;width: 100%;border-radius: 0;border: none;border-right: 1px solid #eeeeee;display: block;height: 45px;line-height: 45px;color: #7d7d7d;padding-left: 29px;padding-right: 20px;padding-top: 0;padding-bottom: 0;font-size: 14.5px;">
                                    <option value="">Dönem Seçiniz</option>
                                    <option *ngFor="let item of getperiodModel" [value]="item.title"> {{item.title}}
                                    </option>
        
                                </select>
                            </div>
                        </div>
                    </div>
        
                    <div class="main-search-btn">
                        <button  style="background-color: #85cbd3;" type="submit" (click)="searchTour()">Ara <i
                                class='bx bx-search-alt'></i></button>
                    </div>
                </form>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-6"  *ngFor="let item of gettourModel">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details/{{item.id}}" class="d-block"><img img [src]="path+'tours/'+item.tourMedias[0]?.fileName" alt="Image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">{{item.regionRecord.title}}</a>
                        </div>

                        <!-- <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 
                            </div>
                        </div> -->
                    </div>

                    <div class="listing-content">
                        <!-- <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div> -->

                        <h3><a routerLink="/listing-details/{{item.id}}" class="d-inline-block">{{item.tourRecord.title}}</a></h3>

                        <span class="location"><img src="assets/img/vakurtarih.png" alt="" style="margin-left: -23px;width: 8%;">{{item?.dayCount-1}} Gece {{item?.dayCount}} Gün |{{item?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{item?.endDate | date:'dd/MM/yyyy' :'TR'}}</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    <strong style="color: #088dd3;">{{item.tourPrices[0].price}} SAR</strong>
                                    <strong style="color: black;">'dan başlayan fiyatlarla</strong>
                                </span>
                            </div>

                            <div class="listing-option-list">
                               
                                <!-- <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a> -->
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Aktİf</div>
                </div>
            </div>

<!-- 
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <a routerLink="/" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/" class="page-numbers">2</a>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="page-numbers">5</a>
                    <a routerLink="/" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>