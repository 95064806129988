import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TourResource } from "../models/tour-resource";
import { Reservation } from "../models/reservation";
import { TourClick } from "../models/click";

@Injectable({
    providedIn: 'root'
})
export class TourService {

    path: string = environment.apiUrl;
    lang: string = "tr"
    http: any;



    constructor(
        private httpClient: HttpClient) { }

    getList(): any {
        return this.httpClient.get<any>(this.path + "/ActiveTour/GetAllTourByLang/" + this.lang);
    }
    getTourList(): any {
        return this.httpClient.get<any>(this.path + "/Tour/GetAllTourByLang/" + this.lang);
    }
    getid(id: string): Observable<any> {
        return this.httpClient.get<any>(this.path + "/ActiveTour/GetActiveTour/" + id)
        // return this.httpClient.get<any>(this.path+"/ActiveTour/GetAllTourByLang/" + this.lang);
    }
    // addReservation(user: Reservation): Observable<Reservation> {
    //     const url = `/Reservation/AddTourReservation`;
    //     return this.httpClient.post<Reservation>(this.path+url, user)
    //   }
    addReservation(user: Reservation): Observable<Reservation> {
        return this.httpClient.post<any>(this.path + "/Reservation/AddTourReservation", user);
    }


    getTourSearch(resource: TourResource) {
        const customParams = new HttpParams()
            .set('languageCode', "tr")
            .set('Name', resource.TourName)
            .set('SearchTerm', resource.SearchTerm)

            .set('regionName', resource.RegionName)
            .set('periodName', resource.PeriodName)
            .set('regionId', resource.RegionId)
            .set('periodId', resource.PeriodId)
            .set('categoryName', resource.CategoryName)
            .set('departureName', resource.DepartureName)
            .set('transportaionName', resource.TransportaionName)

        return this.httpClient.get<any[]>(this.path + "/ActiveTour/GetAllTourSearchByLang/" + this.lang, {
            params: customParams,
            observe: 'response'
        });
        return
    }

    //    getRegions(resource:TourResource):Observable<any>{
    //     return this.httpClient.get<any>(this.path+"/TourOption/GetTourRegions/")

    //    }
    getRegions(resource: TourResource) {
        const customParams = new HttpParams()
            .set('languageCode', "tr")
            .set('Name', resource.TourName)
            .set('regionName', resource.RegionName)
        return this.httpClient.get<any>(this.path + "/TourOption/GetTourRegions/", {
            params: customParams,
            observe: 'response'
        });
        return
    }

    GetsearchTour(TourName: any, PeriodName: any, CategoryName: any, SearchTerm: any, RegionName:any) {
        const url = `/ActiveTour/GetAllTourSearchByLang?LanguageCode=tr&TourName=${TourName}&PeriodName=${PeriodName}&CategoryName=${CategoryName}&SearchTerm=${SearchTerm}&RegionName=${RegionName}`;
        return this.httpClient.get(this.path + url);

    }

//     public getIPAddress() 
//   { 
//     return this.httpClient.get("http://api.ipify.org/?format=json"); 
//   }

  postclick(data:TourClick): Observable<TourClick> {
    return this.httpClient.post<any>(this.path + "/Tour/AddTourClick/",data)
}

 getIPAddress()
  {
   return  this.httpClient.get("http://api.ipify.org/?format=json")
  }
}