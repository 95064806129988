<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>Checkout</h2> -->
        </div>
    </div>
</div>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <!-- <span *ngIf="tourDetail.isChild == false"><i class='bx bx-calendar'></i>{{tourDetail?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{tourDetail?.endDate | date:'dd/MM/yyyy' :'TR'}} <i class='bx bx-log-in'></i> {{tourDetail.tourRecord.title}}-{{personCount}} Yetişkin | | {{countedPrice}}₺  </span> -->
            <span *ngIf="tourDetail.isChild == false"><i class='bx bx-calendar'></i>{{tourDetail?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{tourDetail?.endDate | date:'dd/MM/yyyy' :'TR'}} <i class='bx bx-log-in'></i> {{tourDetail.tourRecord.title}}-{{personCount}} Yetişkin | {{childCount}} Çocuk  | {{countedPrice}}₺</span>
        </div>

        <form [formGroup]="reservationForm">
            <div class="book-now-content">
                <h3>İletişim ve Fatura Bilgileri</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Ad</label>
                            <input type="text" formControlName="ContactFirstName" id="ContactFirstName" class="form-control">
                            <div *ngIf="reservationForm.get('ContactFirstName').touched && reservationForm.get('ContactFirstName').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactFirstName').errors?.required">
                                Lütfen ad giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Soyad</label>
                            <input type="text" formControlName="ContactLastName" id="ContactLastName" class="form-control">
                            <div *ngIf="reservationForm.get('ContactLastName').touched && reservationForm.get('ContactLastName').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactLastName').errors?.required">
                                Lütfen soyad giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Adres</label>
                            <input type="text" formControlName="ContactAddress" id="ContactAddress" class="form-control">
                            <div *ngIf="reservationForm.get('ContactAddress').touched && reservationForm.get('ContactAddress').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactAddress').errors?.required">
                                Lütfen adres giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>No</label>
                            <input type="number" formControlName="ContactDoorNumber" id="ContactDoorNumber" class="form-control">
                            <div *ngIf="reservationForm.get('ContactDoorNumber').touched && reservationForm.get('ContactDoorNumber').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactDoorNumber').errors?.required">
                                Lütfen no giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Posta kodu</label>
                            <input type="text" formControlName="ContactPostalCode" id="ContactPostalCode" class="form-control">
                            <div *ngIf="reservationForm.get('ContactPostalCode').touched && reservationForm.get('ContactPostalCode').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactPostalCode').errors?.required">
                                Lütfen posta kodu giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Şehir</label>
                            <input type="text" formControlName="ContactCity" id="ContactCity" class="form-control">
                            <div *ngIf="reservationForm.get('ContactCity').touched && reservationForm.get('ContactCity').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactCity').errors?.required">
                                Lütfen Şehir giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Ülke</label>
                            <input type="text" formControlName="ContactCountry" id="ContactCountry" class="form-control">
                            <div *ngIf="reservationForm.get('ContactCountry').touched && reservationForm.get('ContactCountry').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactCountry').errors?.required">
                                Lütfen ülke giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Telefon</label>
                            <input type="number" formControlName="ContactPhone" id="ContactPhone" class="form-control">
                            <div *ngIf="reservationForm.get('ContactPhone').touched && reservationForm.get('ContactPhone').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactPhone').errors?.required">
                                Lütfen telefon numarası giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="text" formControlName="ContactEmail" id="ContactEmail" class="form-control">
                            <div *ngIf="reservationForm.get('ContactEmail').touched && reservationForm.get('ContactEmail').errors">
                            <div class="text-danger" *ngIf="reservationForm.get('ContactEmail').errors?.required">
                                Lütfen E-posta giriniz
                            </div>
                            <div class="text-danger" *ngIf="reservationForm.get('ContactEmail').errors?.email">
                                Lütfen Geçerli bir e-posta giriniz
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">   
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (change)="showDiffInvoice = !showDiffInvoice" id="diff">
                                <label class="form-check-label" for="diff">
                                    Farklı fatura adresi belirtmek istiyorum
                                </label>
                            </div>
                        </div>
                        <div class="row" *ngIf="showDiffInvoice">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Ad</label>
                                    <input type="text" formControlName="InvoiceTitle" id="InvoiceTitle" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoiceTitle').touched && reservationForm.get('InvoiceTitle').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceTitle').errors?.required">
                                        Lütfen ad giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Soyad</label>
                                    <input type="text" formControlName="InvoiceTitle" id="InvoiceTitle" class="form-control">
                                    <div *ngIf="reservationForm.get('ContactLastName').touched && reservationForm.get('InvoiceTitle').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceTitle').errors?.required">
                                        Lütfen soyad giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Ünvan</label>
                                    <input type="text" formControlName="InvoiceTitle" id="InvoiceTitle" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoiceTitle').touched && reservationForm.get('InvoiceTitle').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceTitle').errors?.required">
                                        Lütfen ünvan giriniz
                                    </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Adres</label>
                                    <input type="text" formControlName="InvoiceAddress" id="InvoiceAddress" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoiceAddress').touched && reservationForm.get('InvoiceAddress').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceAddress').errors?.required">
                                        Lütfen adres giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>No</label>
                                    <input type="number" formControlName="InvoiceDoorNumber" id="InvoiceDoorNumber" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoiceDoorNumber').touched && reservationForm.get('InvoiceDoorNumber').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceDoorNumber').errors?.required">
                                        Lütfen no giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Posta kodu</label>
                                    <input type="text" formControlName="InvoicePostalCode" id="InvoicePostalCode" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoicePostalCode').touched && reservationForm.get('InvoicePostalCode').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoicePostalCode').errors?.required">
                                        Lütfen posta kodu giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Ülke</label>
                                    <input type="text" formControlName="InvoiceCountry" id="InvoiceCountry" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoiceCountry').touched && reservationForm.get('InvoiceCountry').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceCountry').errors?.required">
                                        Lütfen ülke giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Şehir</label>
                                    <input type="text" formControlName="InvoiceCity" id="InvoiceCity" class="form-control">
                                    <div *ngIf="reservationForm.get('InvoiceCity').touched && reservationForm.get('InvoiceCity').errors">
                                    <div class="text-danger" *ngIf="reservationForm.get('InvoiceCity').errors?.required">
                                        Lütfen Şehir giriniz
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div formArrayName="ReservationPersons">
                <div class="book-now-content" *ngFor="let person of ReservationPersons.controls; let i= index">
                    <h3>{{i+1}}. Kişi Bilgileri</h3>
                    <div class="row" [formGroupName]="i">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Ad </label>
                                <input type="text" formControlName="FirstName" id="FirstName" class="form-control">
                                <div *ngIf="person.get('FirstName').touched && person.get('FirstName').errors">
                                    <div class="text-danger" *ngIf="person.get('FirstName').errors?.required">
                                    Lütfen ad giriniz
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Soyad </label>
                                <input type="text" formControlName="LastName" id="LastName" class="form-control">
                                <div *ngIf="person.get('LastName').touched && person.get('LastName').errors">
                                    <div class="text-danger" *ngIf="person.get('LastName').errors?.required">
                                    Lütfen soyad no giriniz
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Telefon </label>
                                <input type="number" formControlName="Phone" id="Phone" class="form-control">
                                <!-- <div *ngIf="person.get('Phone').touched && person.get('Phone').errors">
                                    <div class="text-danger" *ngIf="person.get('Phone').errors?.required">
                                    Lütfen Telefon giriniz
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Doğum Tarihi </label>
                                <input type="date" formControlName="BirthDay" id="BirthDay" class="form-control">
                                <div *ngIf="person.get('BirthDay').touched && person.get('BirthDay').errors">
                                    <div class="text-danger" *ngIf="person.get('BirthDay').errors?.required">
                                    Lütfen Doğum Tarihi
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>Cinsiyet</label>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="Gender" id="Gender" value="0">
                                    <label class="form-check-label" for="male">
                                        Erkek
                                    </label>
                                   
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="Gender" id="female" value="1">
                                    <label class="form-check-label" for="female">
                                        Kadın
                                    </label>
                                </div>
                                <div *ngIf="person.get('Gender').touched && person.get('Gender').errors">
                                    <div class="text-danger" *ngIf="person.get('Gender').errors?.required">
                                    Lütfen Cinsiyet seçiniz
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="book-now-content">
                <button class="default-btn" type="submit" (click)="addReservation()"> Rezervasyon Yap</button>
            </div>

        </form>
    </div>
</section>