<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/Vakur_iletisim_01.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <!-- <span class="sub-title">Download App</span> -->
                    <h2 style="color: #fff;">{{getcommunicationModel?.name}}</h2>
                    <p style="color: #fff;">{{getcommunicationModel?.value}}</p>

                    <div class="btn-box">
                        <a href="https://api.whatsapp.com/send?phone=5427342741"  class="apple-store-btn">
                            <img src="assets/img/iletisim_icon01.png" alt="image" style="width: 15%;margin-left: -12px;">
                            
                            <span>{{getsettingModel?.name}}</span>
                        </a>

                        <a  href="tel:+3125522323"class="play-store-btn">
                            <img src="assets/img/iletisim_icon02.png" alt="image" style="width: 15%;margin-left: -12px;">
                            
                            <span>{{getsettingModel?.value}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
