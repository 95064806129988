<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>Listing</h2> -->
        </div>
    </div>
</div>

<section class="listing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="listing-widget-area">
                    <!-- <div class="listing-widget filter-list-widget">
                        <h3 class="listing-widget-title">Şu anki seçim</h3>

                        <div class="selected-filters-wrap-list">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Free WiFi</a></li>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Shops</a></li>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Hospital</a></li>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Free Parking</a></li>
                            </ul>

                            <a routerLink="/" class="delete-selected-filters"><i class='bx bx-trash'></i> <span>Clear All</span></a>
                        </div>
                    </div> -->
                    

                    <div class="listing-widget facilities-list-widget">
                        <h3 class="listing-widget-title">Bölgeler</h3>

                        <ul class="facilities-list-row">
                        <li *ngFor="let item of getregionModel"><a routerLink="/">{{item.title}}</a></li>
                        </ul>
                    </div>
                    <!-- <div class="listing-widget price-list-widget">
                        <h3 class="listing-widget-title">Fiyat</h3>

                        <div class="collection-filter-by-price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div> -->

                    <div class="listing-widget categories-list-widget">
                        <h3 class="listing-widget-title">Kategoriler</h3>

                        <ul class="categories-list-row">
                            <li *ngFor="let item of getcategoryModel"><a routerLink="" (click)="selectedCategoryTour(getlistModel[2].title)">{{item.title}}</a></li>
                        </ul>
                    </div>
                    <div class="listing-widget facilities-list-widget">
                        <h3 class="listing-widget-title">Dönemler</h3>

                        <ul class="facilities-list-row">
                            <li  *ngFor="let item of getperiodModel"><a routerLink="">{{item.title}}</a></li>
                        </ul>
                    </div>
                    <!-- <div class="listing-widget categories-list-widget">
                        <h3 class="listing-widget-title">Ulaşımlar</h3>

                        <ul class="categories-list-row">
                            <li  *ngFor="let item of getflightModel"><a routerLink="/">  {{item.tourTransportaionRecords.length > 0 ? item.tourTransportaionRecords[0].transportaionName:""}}</a></li>

                        </ul>
                    </div> -->

                    <div class="listing-widget aside-trending-widget">
                        <div class="aside-trending-products">
                            <img src="assets/img/listing/img1.jpg" alt="image">

                            <div class="category">
                                <h3>En İyi tur</h3>
                                <span>İlkbahar/Yaz 2020 Koleksiyonu</span>
                            </div>
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="listing-filter-options">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <p> {{gettourModel.length}} Tur</p>
                        </div>

                        <div class="col-lg-5">
                            <div class="listing-ordering-list">
                                <select>
                                    <option>Fiyata Göre Sıralara: Düşükten Yükseğe</option>
                                    <option>Fiyata Göre Sıralara: Yüksekten Düşüge</option>
                                    <option>Varsayılan sıralama</option>
                                    <option>En yeniye göre sırala</option>
                                  
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6"  *ngFor="let item of gettourModel">
                        <div class="single-listing-item"  *ngIf="item.isActive == true">
                            <div class="listing-image">
                                <a routerLink="/listing-details/{{item.id}}" class="d-block"><img img [src]="path+'tours/'+item.tourMedias[0]?.fileName" alt="Image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">{{item.regionRecord.title}}</a>
                                </div>

                              
                            </div>

                            <div class="listing-content">
                               

                                <h3><a routerLink="/listing-details/{{item.id}}" class="d-inline-block">{{item.tourRecord.title}}</a></h3>

                                <span class="location"><i class="bx bx-calendar"></i>{{item?.dayCount-1}} Gece {{item?.dayCount}} Gün |{{item?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{item?.endDate | date:'dd/MM/yyyy' :'TR'}}</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                            <strong style="color: #088dd3;">{{item.tourPrices[0].price}}₺</strong>
                                            <strong style="color: black;">'dan başlayan fiyatlarla</strong>
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                      
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">Aktİf!</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-6"  *ngFor="let item of gettourModel">
                        <div class="single-listing-item" *ngIf="item.isActive == false">
                            <div class="listing-image">
                                <a routerLink="/listing-details/{{item.id}}" class="d-block"><img img [src]="path+'tours/'+item.tourMedias[0]?.fileName" alt="Image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">{{item.regionRecord.title}}</a>
                                </div>

                              
                            </div>

                            <div class="listing-content">
                               

                                <h3><a routerLink="/listing-details/{{item.id}}" class="d-inline-block">{{item.tourRecord.title}}</a></h3>

                                <span class="location"><i class="bx bx-calendar"></i>{{item?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{item?.endDate | date:'dd/MM/yyyy' :'TR'}}</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                            <strong style="color: #088dd3;">{{item.tourPrices[0].price}}₺</strong>
                                            <strong style="color: black;">'dan başlayan fiyatlarla</strong>
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                      
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge closed">KAPALI!</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/listing-two" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                           
                            <a routerLink="/listing-two" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>